body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.token-container{
  border: 1px solid #aaaaaa;
  padding: 5px 10px;
  border-radius: 5px;
  word-break: break-all;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 400;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 10px
}

.token-container pre {
  overflow-x: auto;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}

.token-title, .token-message-title{
  font-size: 1rem;
  text-align: center;
}
.token-title span{
  font-size: 0.7rem;
}

.token-message {
  font-size: 1rem;
  text-align: left;
}
.token-part-title{
  font-size: 1rem;
  text-align: left;
}
.token-header, .token-body {
  line-height: 1.43;
  color: #fb015b;
  text-align: left;
}

.token-body {
  color: #d63aff;
}

.scope {
    border: 1px solid;
    border-color: #00000035;
    border-radius: 4px;
    margin-bottom: 20px;
}

.scope:hover {
  border-color: #00000099;
}

.scope-legend {
  font-size: 0.8rem;
  color: #00000095;
}

